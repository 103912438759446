import { useFetcher } from "@remix-run/react";
import Button from "antd-mobile/es/components/button";
import Toast from "antd-mobile/es/components/toast";
import { useCallback, useEffect, useState } from "react";
import { useAccountSelectorContext } from "~/components/context/accountContext";
import BrandIcon from "~/components/icons/BrandIcon";
import { useRootData } from "~/utils/data/useRootData";
import clsx from "~/utils/shared/ClassesUtils";
import { closeConfirmModal, confirmModal } from "./confirmModal";

interface ForkedProps {
  forked?: boolean;
  id?: string;
  buttonProps?: any;
  afterClick?: (newState: boolean) => void;
  editable?: boolean;
  name?: string;
  forkedButton?: boolean;
  type: string;
  image?: string;
  showButton?: boolean;
}

export default function Forked({
  forked,
  id,
  buttonProps = {},
  afterClick,
  editable = true,
  name,
  forkedButton,
  type = "recipe",
  image,
  showButton = false,
}: ForkedProps) {
  const rootData = useRootData();
  const [currentForked, setCurrentForked] = useState(forked);
  const openAccount = useAccountSelectorContext((state) => state.openAccount);

  const forkedFetcher = useFetcher();
  const { className, ...rest } = buttonProps;
  useEffect(() => {
    setCurrentForked(forked);
  }, [forked, id]);

  const handleForkRecipe = useCallback(async () => {
    closeConfirmModal();
    const newState = !currentForked;
    if (id) {
      setCurrentForked(newState);
      const form = new FormData();
      form.set("action", `${currentForked ? "un" : ""}fork-${type}`);
      form.set("recipeId", id);
      await forkedFetcher.submit(form, {
        method: "post",
        action: "/api/recipe/fork",
      });

      if (newState) {
        Toast.show({ content: "Recipe Forked", icon: <BrandIcon color1={"fill-theme-300"} className={"cursor-pointer"} />, duration: 1000 });
      }
    }
    if (afterClick) {
      afterClick(newState);
    }
  }, [forkedFetcher, currentForked, id, type]);

  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      if (editable && rootData.hasSub) {
        if (currentForked) {
          confirmModal({
            header: (
              <div
                className={clsx("flex h-20 w-20 min-w-full items-end rounded-full bg-cover bg-center text-white")}
                style={{ backgroundImage: `url("${image}?class=w40h40")` }}
              ></div>
            ),
            title: (
              <div className="py-4">
                <div>Un-Fork</div>
                <div>{name}</div>
              </div>
            ),
            // content: (
            //   <div className="mt-3 text-center sm:mt-5">
            //     <h3 className="text-base font-semibold leading-6 text-gray-900">{`${name}`}</h3>
            //     <div className="mt-2">
            //       <p className="text-sm text-gray-500">{`Are you sure you want to un-fork ${name || "this recipe"}?`}</p>
            //       <p className="text-sm text-gray-500">{`This will remove it from your forked recipes.`}</p>
            //     </div>
            //   </div>
            // ),
            actions: [
              [
                {
                  key: "cancel",
                  text: "Cancel",
                  className: "adm-button-default",
                  onClick: closeConfirmModal,
                },
                {
                  key: "unfork",
                  text: "Un-Fork",
                  className: "button-fill-important",
                  danger: true,
                  onClick: handleForkRecipe,
                },
              ],
            ],
          });

          //need to confirm they want to un-fork
        } else {
          handleForkRecipe();
        }
      }
      if (!rootData.hasSub) {
        // Toast.show({ content: "Please subscribe to fork recipes", icon: <BrandIcon color1={"fill-theme-300"} className={"cursor-pointer"} />, duration: 1000 });
        openAccount("popup", "forked_icon");
      }
    },
    [currentForked, editable, rootData.hasSub, image, name, id]
  );

  // if (forkedButton) {
  //   return (
  //     <Button
  //       // shape={"rounded"}
  //       color={currentForked ? "primary" : "default"}
  //       onClick={handleClick}
  //       {...rest}
  //     >
  //       <div className="flex w-full items-center justify-center space-x-2">
  //         <span className="sr-only">{currentForked ? "Un-Fork this Recipe" : "Fork this Recipe"}</span>
  //         <BrandIcon color1={currentForked ? "white" : undefined} className={"h-5 w-5 cursor-pointer"} />
  //         <span>{currentForked ? "Un-Fork" : "Fork"}</span>
  //       </div>
  //     </Button>
  //   );
  // }

  return (
    <>
      <Button
        shape={"rounded"}
        size={"small"}
        className={clsx("popover-button !block flex-none !p-0", className)}
        // fill={"none"}
        onClick={handleClick}
        {...rest}
      >
        <span className="sr-only">{currentForked ? "Un-Fork this Recipe" : "Fork this Recipe"}</span>
        <BrandIcon color1={currentForked ? "fill-theme-300" : "fill-gray-300"} className={"cursor-pointer"} />
      </Button>
      {showButton && (
        <div className="mt-2 flex flex-none justify-center gap-2">
          {/* <BrandIcon className="h-4 w-4" /> */}
          <Button size="small" className={"flex-1 shadow-sm"} color={currentForked ? "default" : "primary"} onClick={handleClick}>
            {currentForked ? "Forked" : "Fork"}
          </Button>
        </div>
      )}
    </>
  );
}
